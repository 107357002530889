<template>
    <div class="bg-white box-shadow border-r px-20 pb-20">
        <div class="d-flex-center px-15 border-bottom">
            <h4 class="mr-auto color-333">Change Login Password</h4>
        </div>
        <div v-loading="loading" class="p-20">
            <el-form
                ref="ruleForm"
                class="input-form mx-auto"
                :model="formData"
                :show-message="false"
                :status-icon="true"
                :rules="rules"
            >
                <el-form-item prop="old_password">
                    <el-input v-model="formData.old_password" prefix-icon="el-icon-unlock" show-password placeholder="Enter Old Password" />
                </el-form-item>
                <el-form-item prop="password">
                    <el-input v-model="formData.password" prefix-icon="el-icon-lock" show-password placeholder="Enter New Password" />
                </el-form-item>
                <el-form-item prop="re_password">
                    <el-input v-model="formData.re_password" prefix-icon="el-icon-lock" show-password placeholder="Re-Enter New Password" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-s-promotion" class="w-100" native-type="button" @click="submitForm">Submit</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            formData: {
                old_password: null,
                password: null,
                re_password: null
            },
            rules: {
                old_password: [
                    { required: true, trigger: 'blur' }
                ],
                password: [
                    { required: true, trigger: 'blur' }
                ],
                re_password: [
                    { required: true, trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        submitForm: async function () {
            try {
                await this.$refs.ruleForm.validate()
                if(this.formData.password != this.formData.re_password){
                    this.$message({
                        message: 'The password entered twice must be the same',
                        type: 'error'
                    })
                    return
                }
                this.loading = true
                await this.$request.post('user/change_password', this.formData)
                this.formData.old_password = null
                this.formData.password = null
                this.formData.re_password = null
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.input-form{
    width: 400px;
}
.voucher-img{
    width: 320px;
    height: 180px;
}
</style>